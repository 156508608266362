.header-img {
  max-height: 570px;
}

.bottom-btn {
  width: 40%;
}

.free-demo-bg {
  /* background-color: #04B486; */
  background: #0B3B2E;
  background: linear-gradient(360deg, #0B3B2E 0%, #000 44%, #000 100%);
  /* For browsers that do not support gradients */
  /* background-image: linear-gradient(to bottom, #04B486, #04B486, #16fabd); */
}

.my-btn-bg {
  background: #04B486;
  background: linear-gradient(0deg, #04B486 0%, #04B486 50%, #04B486 100%);
}

.txt-shadow {
  text-shadow: 1px 1px green;
}

.txt-shadow1 {
  text-shadow: 1px 1px rgb(255, 255, 255);
}

.home-item-img {
  flex: 1;
  background-color: rgb(141, 140, 140);
  min-width: 350px;
}

.home-item-txt-right {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-left: 50px;
  min-width: 350px;
}

.home-item-txt-left {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-right: 50px;
  padding-left: 10px;
  min-width: 350px;
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (max-width: 900px) {
  .header-img {
    max-height: 570px;
  }

  .bottom-btn {
    width: 80%;
  }

  .home-item-txt-right {
    flex: 1;
    align-self: center;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
    text-align: center;
    padding-left: 0px;
  }

  .home-item-txt-left {
    flex: 1;
    align-self: center;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
    text-align: center;
    padding-right: 0px;
  }
}

.App {
  text-align: center;
}

.my-input {}

.my-input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: red;
  opacity: 1;
  /* Firefox */
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.main {
  color: black;
  padding: 15px;
  font-size: 16px;
  display: flex;
  flex: 1;
  flex-direction: column
}

/* login form */
.form {
  width: 330px;
  padding: 15px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  background-color: lightgray;
}

.add-project-form {
  padding: 15px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  background-color: #f2f2f2;
}

.about-card-p {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.about-card {
  padding: 10px;
  background: lightblue;
  flex-grow: 1;
}

.separater {
  height: 10px;
}


/* Projects Page */
.titlebar {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.projects-titlebar {
  display: flex;
  margin-top: 10px;
  align-items: center;
  padding: 5px;
}

.projects-titlebar-photo {
  font-size: 15px;
  color: gray;
  margin-right: 20px;
}

.projects-titlebar-title {
  flex: 1.5;
  font-size: 15px;
  color: gray;
}

.projects-titlebar-cat {
  flex: 1;
  font-size: 15px;
  color: gray;
}

.projects-titlebar-btns {
  display: flex;
  flex: 1;
  font-size: 15px;
  color: gray;
}

.projects-itembar {
  display: flex;
  margin-top: 0px;
  align-items: center;
  padding: 10px;
}

.projects-itembar-colored {
  display: flex;
  align-items: center;
  background: #f2f2f2;
  border-radius: 5px;
  padding: 10px;
}

.projects-item-photo {
  font-size: 13px;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
}

.projects-item-title {
  flex: 1.5;
  font-size: 14px;
}

.projects-item-cat {
  flex: 1;
  font-size: 13px;
}

.projects-item-btns {
  display: flex;
  flex: 1;
  font-size: 13px;
}

.btn-view {
  margin-right: 5px;
  flex: 1;
}

.btn-edit {
  flex: 1;
  width: 70px
}

.btn-submit {
  max-width: 300px;
  align-self: center;
}